import {useState, useEffect} from 'react';
import { httpGet, APIEndpoint, EndpointType } from '../utils/apiService'
import { Communication, CommunicationsResponse } from '../models/communication';

const apiURL =  APIEndpoint(EndpointType.communications);

export const useGetCommunications = (clientID: string, page: string, refreshToken: number) => {
    const [communications, setCommunications] = useState<Communication[]>()
    const [communicationsPage, setCommunicationsPage] = useState("")
    const [communicationsError, setCommunicationsError] = useState("")
    const [communicationsLoading, setCommunicationsLoading] = useState(false)

    useEffect(() => {
        if (refreshToken === 0) return;

        setCommunications([])
        setCommunicationsPage("")
        setCommunicationsError("")
        setCommunicationsLoading(true)

        const pageParam = page === "" ? "" : `&page=${page}`
        const params = `?client-id=${clientID}${pageParam}`

        const url = `${apiURL}${params}`
        console.log(url)
        httpGet(url)
            .then((data) => {
                const response = data as CommunicationsResponse;
                
                if (response.status === "error") {
                    setCommunicationsError(response.errorMessage)
                } else {
                    if (response.communications) {
                        setCommunications(response.communications);
                        setCommunicationsPage(response.page);
                    } 
                }
            })
            .catch((error) => setCommunicationsError(error.message || "Error fetching communications"))
            .finally(() => setCommunicationsLoading(false))

    }, [clientID, refreshToken, page]);

    return { communications, communicationsPage, communicationsError, communicationsLoading};
};
