import {useState, useEffect} from 'react';
import { httpGet, APIEndpoint, EndpointType } from '../utils/apiService'
import { User, UsersResponse } from '../models/users';

const apiURL =  APIEndpoint(EndpointType.users);

export const useGetUsers = (page: string, refreshToken: number) => {
    const [users, setUsers] = useState<User[]>()
    const [usersPage, setUsersPage] = useState("")
    const [usersError, setUsersError] = useState("")
    const [usersLoading, setUsersLoading] = useState(false)
    const [autocompleteUsers, setAutocompleteUsers] = useState<AutocompleteUser[]>([])

    useEffect(() => {
        if (refreshToken === 0) return;

        setUsers([])
        setAutocompleteUsers([])
        setUsersPage("")
        setUsersError("")
        setUsersLoading(true)

        const pageParam = page === "" ? "" : `?page=${page}`

        const url = `${apiURL}${pageParam}`
        console.log(url)
        httpGet(url)
            .then((data) => {
                const response = data as UsersResponse;
                
                if (response.status === "error") {
                    setUsersError(response.errorMessage)
                } else {
                    if (response.users) {
                        setUsers(response.users);
                        setAutocompleteUsers(createAutocompleteUsers(response.users));
                        setUsersPage(response.page);
                    } 
                }
            })
            .catch((error) => setUsersError(error.message || "Error fetching Users"))
            .finally(() => setUsersLoading(false))

    }, [refreshToken, page]);

    return { users, autocompleteUsers, usersPage, usersError, usersLoading};
};

function createAutocompleteUsers(users: User[]) {
    const autocompleteUsers: AutocompleteUser[] = [];

    for (const user of users) {
        const newAutocompleteUser: AutocompleteUser = {
            label: user.firstName + " " + user.lastName + " (" + user.email + ")",
            id: user.id
        };
        autocompleteUsers.push(newAutocompleteUser);
    }

    return autocompleteUsers.sort((a, b) => a.label.localeCompare(b.label));
}

export interface AutocompleteUser {
    label: string,
    id: string
}