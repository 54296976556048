import { BaseResponse, DataStoreBase } from "./base";
import { Note } from "./note";

export interface CommunicationsResponse extends BaseResponse {
    status: string,
    requestID: string,
    timestamp: string,
    errorMessage: string,
    page: string,
    communications: Communication[],
}

export interface Communication extends DataStoreBase {
    clientID: string,
    dealID: string,
    contactID: string,
    communicationType: CommunicationType,
    notes?: Note[],
    timestamp: string,
    emailCommunication?: EmailCommunication,
    phoneCommunication?: PhoneCommunication,
    textCommunication?: TextCommunication,
    phoneBotCommunication?: PhoneBotCommunication,
    textBotCommunication?: TextBotCommunication,
}

export enum CommunicationType {
    Email = "email",
    Phone = "phone",
    Text = "text",
    PhoneBot = "phone-bot",
    TextBot = "text-bot",
    VideoConference = "video-conference",
    Unknown = ""
}

export enum CommunicationDirection {
    Inbound = "inbound",
    Outbound = "outbound",
    Unknown = ""
}

export interface EmailCommunication {
    subject: string,
    body: string,
    attachments: string[],
    from: string,
    recipientsTo: string[],
    recipientsCC: string[],
    recipientsBCC: string[],
    direction: CommunicationDirection,
    source: string,
    status: string,
}

export interface PhoneCommunication {
    phoneNumber: string,
    duration: number,
    direction: CommunicationDirection,
}


export interface TextCommunication {
    phoneNumber: string,
    direction: CommunicationDirection,
}

export interface PhoneBotCommunication {
}

export interface TextBotCommunication {
    phoneNumber: string,
    direction: CommunicationDirection,
}

export interface VideoConferenceCommunication {
    url: string,
    recordingURL: string,
    recordingS3Uri: string,
    participants: string[],
}