import { Alert, Box, FormControlLabel, Grid, IconButton, Link, Modal, Paper, Snackbar, Stack, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import LoadingProgress from "../../components/LoadingProgress";
import RefreshIcon from "@mui/icons-material/Refresh";
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import JSONInput from "../../components/JSONInput";
import  NoData from "../../components/NoData";
import { APIEndpoint, EndpointType, httpPut } from "../../utils/apiService";
import CloseIcon from "@mui/icons-material/Close";
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { User } from "../../models/users";
import CopyToClipboard from "../../components/CopyToClipboard";
import { useGetvoiceJobs } from "../../hooks/useGetVoiceJobs";
import Checkbox from '@mui/material/Checkbox';
import { useGetvoiceJobStats } from "../../hooks/useGetVoiceJobStats";
import { FunnelChart } from "react-funnel-pipeline";
import 'react-funnel-pipeline/dist/index.css'
import { VoiceJob, VoiceJobResponse } from "../../models/voicejobs";

interface Props {
    activeUser: User
}

interface chartQueueStats {
    name: string;
    value: number;
}
export default function VoiceQueueResources(props: Props) {
    // State
    const [page, setPage] = useState("");
    const [refreshToken, setRefreshToken] = useState(0);
    const [queuedOnly, setQueuedOnly] = useState(false);
    const [openJsonInput, setOpenJsonInput] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [snackbarMsg, setSnackbarMsg] = useState("")
    const [jsonInputData, setJsonInputData] = useState("")
    const [queueStats, setQueueStats] = useState<chartQueueStats[]>();

    // Hooks
    const {voiceJobs, voiceJobsError, voiceJobsLoading, voiceJobsPage} = useGetvoiceJobs(page, refreshToken, queuedOnly);
    const {voiceJobStats, voiceJobStatsError, voiceJobStatsLoading} = useGetvoiceJobStats(refreshToken);

    const columns = [
        { field: 'id', headerName: 'ID', width: 160, renderCell: (params: GridRenderCellParams) => {
            return        <Box><Link
            component="button"
            variant="body2"
            onClick={() => {
              setJsonInputData(JSON.stringify(params.row));
              setOpenJsonInput(true);
            }}
            >
            {params.value}
          </Link><CopyToClipboard value={params.value} /> </Box>
        } },
        { field: 'createdAt', headerName: 'Created At', width: 200 },
        { field: 'priDate', headerName: 'Priority', width: 220 },
        { field: 'queued', headerName: 'Queued', width: 80 },
        { field: 'status', headerName: 'Status', width: 120 },
        { field: 'scheduledStartTime', headerName: 'Sched Start Time', width: 200 },
    ];

    useEffect(() => {
        setRefreshToken(1);
    }, []);

    useEffect(() => {
        // Build the funnel chart data
        const stats: chartQueueStats[] = [];
        if (voiceJobStats) {
            const queued = voiceJobStats.totalCount - voiceJobStats.scheduledCount;
            
            stats.push({name: "Total Calls", value: voiceJobStats.totalCount});
            stats.push({name: "Queued/Running", value: queued});
            stats.push({name: "Running", value: voiceJobStats.runningCount});
        }
        setQueueStats(stats);

    }, [voiceJobStats]);

    const handleJsonSave = (json: string) => {
        console.log("saving json: ", json)
        setOpenJsonInput(false);

        const voiceJob = JSON.parse(json) as VoiceJob;
        if (voiceJob.id === "") {
            setSnackbarMsg("Creating new items is not supported")
            setOpenSnackbar(true)
        } else {
            updateItem(json, voiceJob.id)
        }
    }

    const updateItem = (json: string, id: string) => {
        const apiURL =  APIEndpoint(EndpointType.voicequeue) + `/${id}`
        httpPut(apiURL, json)
        .then((data) => {
            const response = data as VoiceJobResponse;
            if (response.status === "success") {
                setSnackbarMsg("Updated successfully")
                setOpenSnackbar(true)
                setRefreshToken(refreshToken + 1)
            } else if (response.status === "error") {
                setSnackbarMsg(response.errorMessage || "Error updating item")
                setOpenSnackbar(true)
            }
            
        })
        .catch((error) => { 
            console.log("Update error: " + error.message)
        });  
    }

    const handleAddItemClose = () => {
        setOpenJsonInput(false);
    };

    const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
    };

    const handleQueueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQueuedOnly(event.target.checked);
      };

    const snackbarAction = (
        <Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleSnackbarClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </Fragment>
    );

    const modalStyle = {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: '#636363',
        border: '1px solid #ededed',
        boxShadow: 24,
        p: 1,
        height: 800,
    };

    return (
        <Paper sx={{ margin: 1 }}>
            <Stack direction="row" spacing={1} alignItems="flex-start" justifyContent="space-between">
                <Typography variant="h5" sx={{ ml: 1 }}>Voice Call Queue Resources</Typography>
                <Stack direction="row" spacing="1">
                    <FormControlLabel control={
                    <Checkbox checked={queuedOnly} onChange={handleQueueChange} inputProps={{ 'aria-label': 'controlled' }} />
                    } label="Show Queued Only" />
                    {voiceJobsPage && voiceJobsPage !== "" &&
                        <IconButton aria-label="Refresh" size="medium" type="button" onClick={() => setPage(voiceJobsPage)} sx={{ height: 40, marginTop: 1 }} >
                            <SkipNextIcon  sx={{color:"#ffff00"}}  />
                        </IconButton>
                    }
                    <IconButton aria-label="Refresh" size="medium" type="button" onClick={() => setRefreshToken(refreshToken + 1)} sx={{ height: 40, marginTop: 1 }} >
                        <RefreshIcon  sx={{color:"#00ffff"}}  />
                    </IconButton>
                </Stack>
            </Stack>
            {voiceJobsLoading && <LoadingProgress title="Getting voice jobs..." />}
            {voiceJobsError && <Alert severity="error" sx={{m:1}}>{voiceJobsError}</Alert>}
            {voiceJobStatsLoading && <LoadingProgress title="Getting queue statistics..." />}
            {voiceJobStatsError && <Alert severity="error" sx={{m:1}}>{voiceJobStatsError}</Alert>}

            <FunnelChart chartWidth={600} chartHeight={400}
                data={queueStats || []}
                />

            <Grid sx={{height:"88vh"}}>
                <DataGrid rows={voiceJobs || []} columns={columns} sx={{ mt: 2 }} slots={{noRowsOverlay: NoData}} checkboxSelection
                                        onRowSelectionModelChange={(ids) => {
                                            const selectedIDs = new Set(ids);
                                            const arrayIDs: string[] = [];
                                            selectedIDs.forEach(v => arrayIDs.push(v.toString()));
                                          }} />

            </Grid>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={snackbarMsg}
                action={snackbarAction}
            />
            <Modal open={openJsonInput} onClose={handleAddItemClose}>
                <Box sx={modalStyle} >
                    <JSONInput title="Create New Scale Unit" json={jsonInputData} onSave={handleJsonSave} />
                </Box>
            </Modal>
        </Paper>
    )
}

