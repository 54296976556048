import { Grid } from "@mui/material";
import * as React from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";

const ApiDocs = () => {
    const docURL = window.location.origin + "/apidocs/swagger.yaml"
    console.log("DocURL: ", docURL)

    return (
        <Grid sx={{ backgroundColor: "white" }}>
            <SwaggerUI url={docURL}  />  
        </Grid>
    )
}

export default ApiDocs;