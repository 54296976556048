import { useState, useEffect } from 'react';
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import { JsonEditor } from "json-edit-react";

interface Props {
    title: string,
    json: string,
    onSave: (json: string) => void
}

export default function JSONInput(props: Props) {
    const [jsonObj, setJsonObj] = useState<object>({})
    const [newJson, setNewJson] = useState<string>("")

    useEffect(() => {
        setJsonObj(JSON.parse(props.json))
    }, [props.json])

    return (
        <Box>
            <Typography variant="h5">{props.title}</Typography>
            <Box sx={{height:696, overflow:"scroll"}}>
                <JsonEditor data={jsonObj} onUpdate={async (nodeData) => {
                const { newData } = nodeData
                  setNewJson(JSON.stringify(newData))
                  }} theme="githubDark"  />
            </Box>
            <Button onClick={() => props.onSave(newJson)} variant="contained" sx={{mt:2, width:120}}>Save</Button>
        </Box>
    );
}
