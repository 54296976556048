import { DataStoreBase } from "./base"

// ScaleUnit represents a running host for the Voice Service
export interface ScaleUnit extends DataStoreBase {
    ipAddress: string
    portNumber: number
    version: string
    scaleUnitUtilizationMetrics: ScaleUnitUtilizationMetrics
    activeCallMetrics: ScaleUnitCallMetrics
    totalCallMetrics: ScaleUnitCallMetrics
    idleTime: number
    upTime: number
    maxConcurrentCalls: number
    status: ScaleUnitStatus
}

// ScaleUnitUtilizationMetrics represents the utilization metrics for a scale unit
export interface ScaleUnitUtilizationMetrics {
    memoryUtilization: number
    cpuUtilization: number
    networkInUtilization: number
    networkOutUtilization: number
}

// ScaleUnitCallMetrics represents the call metrics for a scale unit
export interface ScaleUnitCallMetrics {
    outboundCallCount: number
    inboundCallCount: number
}

export enum ScaleUnitStatus {
    Active = "active",
    Unregistered = "unregistered",
    Disabled = "disabled",
    Unknown = ""
}

export interface ScaleUnitsResponse {
    status: string,
    requestID: string,
    timestamp: string,
    errorMessage: string,
    page: string,
    scaleunits: ScaleUnit[]
}
