import {useState, useEffect} from 'react';
import { httpGet, APIEndpoint, EndpointType } from '../utils/apiService'
import { Contact, ContactsResponse } from '../models/contacts';

const apiURL =  APIEndpoint(EndpointType.contacts);

export const useGetContacts = (clientID: string, page: string, refreshToken: number) => {
    const [contacts, setContacts] = useState<Contact[]>()
    const [contactsPage, setContactsPage] = useState("")
    const [contactsError, setContactsError] = useState("")
    const [contactsLoading, setContactsLoading] = useState(false)

    useEffect(() => {
        if (refreshToken === 0) return;

        setContacts([])
        setContactsPage("")
        setContactsError("")
        setContactsLoading(true)

        const pageParam = page === "" ? "" : `&page=${page}`
        const params = `?client-id=${clientID}${pageParam}`

        const url = `${apiURL}${params}`
        console.log(url)
        httpGet(url)
            .then((data) => {
                const response = data as ContactsResponse;
                
                if (response.status === "error") {
                    setContactsError(response.errorMessage)
                } else {
                    if (response.contacts) {
                        setContacts(response.contacts);
                        setContactsPage(response.page);
                    } 
                }
            })
            .catch((error) => setContactsError(error.message || "Error fetching contacts"))
            .finally(() => setContactsLoading(false))

    }, [clientID, refreshToken, page]);

    return { contacts, contactsPage, contactsError, contactsLoading};
};
