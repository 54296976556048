import { Box, Typography, styled } from "@mui/material";

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
  },
}));

export default function NoData() {
  return (
    <StyledGridOverlay>
      <Box width={100} height={100} sx={{}} >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.46667 10.583341125" version="1.1" x="0px" y="0px"><g transform="translate(-98.96158,-92.669207)">
          <path d="m 102.98242,93.728516 c -0.63741,0 -1.21283,0.05788 -1.64844,0.158203 -0.2178,0.05016 -0.39948,0.108137 -0.54882,0.189453 -0.0747,0.04066 -0.14349,0.08718 -0.20313,0.154297 -0.0596,0.06712 -0.11328,0.165934 -0.11328,0.279297 0,0.113362 0.0537,0.214132 0.11328,0.28125 0.0596,0.06712 0.12846,0.111686 0.20313,0.152343 0.14934,0.08132 0.33102,0.141246 0.54882,0.191407 0.43561,0.100321 1.01103,0.158202 1.64844,0.158203 0.63742,0 1.21478,-0.05788 1.65039,-0.158203 0.21781,-0.05016 0.39949,-0.110091 0.54883,-0.191407 0.0747,-0.04066 0.14154,-0.08522 0.20117,-0.152343 0.0219,-0.02465 0.0232,-0.07103 0.041,-0.103516 a 0.26458299,0.26458299 0 0 0 0.0723,-0.177734 0.26458299,0.26458299 0 0 0 -0.0664,-0.164063 c -0.0191,-0.03746 -0.0223,-0.08753 -0.0469,-0.115234 -0.0596,-0.06712 -0.1265,-0.113639 -0.20117,-0.154297 -0.14934,-0.08132 -0.33102,-0.139293 -0.54883,-0.189453 -0.43561,-0.100322 -1.01297,-0.158204 -1.65039,-0.158203 z m 0,0.527343 c 0.60449,0 1.153,0.05937 1.53125,0.146485 0.14713,0.03388 0.23856,0.07145 0.32227,0.107422 -0.0837,0.03598 -0.17508,0.07548 -0.32227,0.109375 -0.37825,0.08711 -0.92676,0.144531 -1.53125,0.144531 -0.60448,-10e-7 -1.15105,-0.05742 -1.52929,-0.144531 -0.14657,-0.03375 -0.23859,-0.07352 -0.32227,-0.109375 0.0837,-0.03585 0.17576,-0.07368 0.32227,-0.107422 0.37824,-0.08711 0.92481,-0.146484 1.52929,-0.146485 z" fill="#a6a6a6" strokeLinecap="round" strokeLinejoin="round" />
          <path d="m 100.73438,95.798828 a 0.26458299,0.26458299 0 0 0 -0.26563,0.265625 c 1e-5,0.113361 0.0536,0.212184 0.11328,0.279297 0.0596,0.06711 0.12846,0.113641 0.20313,0.154297 0.14934,0.08131 0.33102,0.139293 0.54882,0.189453 0.43561,0.100319 1.01104,0.158202 1.64844,0.158203 0.63741,10e-7 1.21478,-0.05788 1.65039,-0.158203 0.21781,-0.05016 0.39949,-0.10814 0.54883,-0.189453 0.0747,-0.04066 0.14153,-0.08718 0.20117,-0.154297 0.0596,-0.06711 0.11327,-0.165935 0.11328,-0.279297 a 0.26458299,0.26458299 0 0 0 -0.26367,-0.265625 0.26458299,0.26458299 0 0 0 -0.26562,0.265625 c 0,-0.04186 0.0218,-0.07258 0.0215,-0.07227 -2.7e-4,3.1e-4 -0.0214,0.01972 -0.0606,0.04102 -0.0782,0.0426 -0.22493,0.09512 -0.41406,0.138672 -0.37825,0.08711 -0.92677,0.144532 -1.53125,0.144531 -0.60447,-10e-7 -1.15105,-0.05742 -1.52929,-0.144531 -0.18913,-0.04355 -0.33778,-0.09607 -0.41602,-0.138672 -0.0391,-0.0213 -0.0583,-0.04071 -0.0586,-0.04102 -2.8e-4,-3.09e-4 0.0195,0.03041 0.0195,0.07227 a 0.26458299,0.26458299 0 0 0 -0.26367,-0.265625 z" fill="#a6a6a6" strokeLinecap="round" strokeLinejoin="round" />
          <path d="m 105.23242,94.246094 a 0.26458299,0.26458299 0 0 0 -0.26562,0.263672 v 3.591796 a 0.26458299,0.26458299 0 0 0 0.26562,0.263672 0.26458299,0.26458299 0 0 0 0.26367,-0.263672 v -3.591796 a 0.26458299,0.26458299 0 0 0 -0.26367,-0.263672 z" fill="#a6a6a6" strokeLinecap="round" strokeLinejoin="round" />
          <path d="m 100.73438,94.246094 a 0.26458299,0.26458299 0 0 0 -0.26563,0.263672 v 4.660156 a 0.26458299,0.26458299 0 0 0 0.26563,0.265625 0.26458299,0.26458299 0 0 0 0.26367,-0.265625 v -4.660156 a 0.26458299,0.26458299 0 0 0 -0.26367,-0.263672 z" fill="#a6a6a6" strokeLinecap="round" strokeLinejoin="round" />
          <path d="m 100.73438,97.353516 a 0.26458299,0.26458299 0 0 0 -0.26563,0.263671 c 1e-5,0.113361 0.0536,0.212184 0.11328,0.279297 0.0596,0.06711 0.12846,0.113641 0.20313,0.154297 0.14934,0.08131 0.33102,0.141247 0.54882,0.191406 0.43561,0.10032 1.01104,0.158202 1.64844,0.158204 0.63741,0 1.21478,-0.05788 1.65039,-0.158204 0.21781,-0.05016 0.39949,-0.110093 0.54883,-0.191406 0.0747,-0.04066 0.14153,-0.08718 0.20117,-0.154297 0.0596,-0.06711 0.11327,-0.165935 0.11328,-0.279297 a 0.26458299,0.26458299 0 0 0 -0.26367,-0.263671 0.26458299,0.26458299 0 0 0 -0.26562,0.263671 c 0,-0.04186 0.0218,-0.07258 0.0215,-0.07227 -2.7e-4,3.1e-4 -0.0214,0.01972 -0.0606,0.04102 -0.0782,0.0426 -0.22493,0.09707 -0.41406,0.140625 -0.37825,0.08711 -0.92677,0.144532 -1.53125,0.144532 -0.60447,-2e-6 -1.15105,-0.05742 -1.52929,-0.144532 -0.18913,-0.04355 -0.33778,-0.09803 -0.41602,-0.140625 -0.0391,-0.0213 -0.0583,-0.04071 -0.0586,-0.04102 -2.8e-4,-3.1e-4 0.0195,0.03041 0.0195,0.07227 a 0.26458299,0.26458299 0 0 0 -0.26367,-0.263671 z" fill="#a6a6a6" strokeLinecap="round" strokeLinejoin="round" />
          <path d="m 100.73438,98.90625 a 0.26458299,0.26458299 0 0 0 -0.26563,0.263672 c 0,0.113362 0.0537,0.214132 0.11328,0.28125 0.0596,0.06712 0.12846,0.113639 0.20313,0.154297 0.14934,0.08132 0.33102,0.139292 0.54882,0.189453 0.43561,0.100322 1.01103,0.158203 1.64844,0.158203 0.35063,0 0.69633,-0.01718 1.01367,-0.05273 a 0.26458299,0.26458299 0 0 0 0.23438,-0.292969 0.26458299,0.26458299 0 0 0 -0.29297,-0.234375 c -0.29496,0.03305 -0.62295,0.05078 -0.95508,0.05078 -0.60448,0 -1.15105,-0.05742 -1.52929,-0.144531 -0.18913,-0.04355 -0.33779,-0.09607 -0.41602,-0.138672 -0.0391,-0.0213 -0.0583,-0.04071 -0.0586,-0.04102 a 0.26458299,0.26458299 0 0 0 -0.24414,-0.193359 z" fill="#a6a6a6" strokeLinecap="round" strokeLinejoin="round" />
          <path d="m 104.80664,98.701172 a 0.26458299,0.26458299 0 0 0 -0.18555,0.07617 0.26458299,0.26458299 0 0 0 0,0.375 L 105.46875,100 a 0.26458299,0.26458299 0 0 0 0.37305,0 0.26458299,0.26458299 0 0 0 0,-0.375 l -0.84766,-0.847656 a 0.26458299,0.26458299 0 0 0 -0.1875,-0.07617 z" fill="#a6a6a6" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M 105.46875,98.777344 104.62109,99.625 a 0.26458299,0.26458299 0 0 0 0,0.375 0.26458299,0.26458299 0 0 0 0.37305,0 l 0.84766,-0.847656 a 0.26458299,0.26458299 0 0 0 0,-0.375 0.26458299,0.26458299 0 0 0 -0.37305,0 z" fill="#a6a6a6" strokeLinecap="round" strokeLinejoin="round" /></g>
        </svg>
        <Typography sx={{ml:2.25, mt:-3}}>No Data</Typography>
      </Box>
    </StyledGridOverlay>
  );
}