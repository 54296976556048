import {useState, useEffect} from 'react';
import { httpGet, APIEndpoint, EndpointType } from '../utils/apiService'
import { ScaleUnit, ScaleUnitsResponse } from '../models/scaleunits';

const apiURL =  APIEndpoint(EndpointType.voicescaleunits);

export const useGetVoiceScaleUnits = (page: string, refreshToken: number) => {
    const [voiceScaleUnits, setVoiceScaleUnits] = useState<ScaleUnit[]>()
    const [voiceScaleUnitsPage, setVoiceScaleUnitsPage] = useState("")
    const [voiceScaleUnitsError, setVoiceScaleUnitsError] = useState("")
    const [voiceScaleUnitsLoading, setVoiceScaleUnitsLoading] = useState(false)

    useEffect(() => {
        if (refreshToken === 0) return;

        setVoiceScaleUnits([])
        setVoiceScaleUnitsPage("")
        setVoiceScaleUnitsError("")
        setVoiceScaleUnitsLoading(true)

        const pageParam = page === "" ? "" : `?page=${page}`

        const url = `${apiURL}${pageParam}`
        console.log(url)
        httpGet(url)
            .then((data) => {
                const response = data as ScaleUnitsResponse;
                
                if (response.status === "error") {
                    setVoiceScaleUnitsError(response.errorMessage)
                } else {
                    if (response.scaleunits) {
                        setVoiceScaleUnits(response.scaleunits);
                        setVoiceScaleUnitsPage(response.page);
                    } 
                }
            })
            .catch((error) => setVoiceScaleUnitsError(error.message || "Error fetching voiceScaleUnits"))
            .finally(() => setVoiceScaleUnitsLoading(false))

    }, [refreshToken, page]);

    return { voiceScaleUnits, voiceScaleUnitsPage, voiceScaleUnitsError, voiceScaleUnitsLoading};
};
