import React from "react";
import { ListItemSecondaryAction, ListItemText, ListItem } from '@mui/material';
import { IconButton } from '@mui/material';
import { Draggable } from "react-beautiful-dnd";

const PipelineDeal = ({ itemObject, index }: { itemObject: any, index: number }) => {

  return (
    <Draggable draggableId={itemObject.id} key={itemObject.id} index={index}>
      {(provided) => (
        <ListItem sx={{height: "50px", backgroundColor: "#10021c", mt:1, }}
          key={itemObject.id}
          dense
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <ListItemText
            primary={`${itemObject.name}`}
          />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="comments"
              question-uid={itemObject.id}
            >
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      )}
    </Draggable>
  );
};

export default PipelineDeal;
