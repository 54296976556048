// pages
import DashboardLayout from "./components/DashboardLayout";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";


// other
import {FC} from "react";
import SignOut from "./pages/SignOut";
import ForgotPassword from "./pages/ForgotPassword";


// interface
interface Route {
    key: string,
    title: string,
    path: string,
    enabled: boolean,
    component: FC<object>
}


export const routes: Array<Route> = [
    {
        key: 'signin-route',
        title: 'Sign In',
        path: '/signin',
        enabled: true,
        component: SignIn
    },
    {
        key: 'signup-route',
        title: 'Sign Up',
        path: '/signup',
        enabled: true,
        component: SignUp
    },
    {
        key: 'signout-route',
        title: 'Sign Out',
        path: '/signout',
        enabled: true,
        component: SignOut
    },
    {
        key: 'dashboard-route',
        title: 'Home',
        path: '*',
        enabled: true,
        component: DashboardLayout
    },
    {
        key: 'forgot-password-route',
        title: 'Forgot Password',
        path: '/forgot-password',
        enabled: true,
        component: ForgotPassword
    },
]