import {useState, useEffect} from 'react';
import { httpGet, APIEndpoint, EndpointType } from '../utils/apiService'
import { Client, ClientsResponse } from '../models/clients';

const apiURL =  APIEndpoint(EndpointType.clients);

export const useGetClients = (page: string, refreshToken: number) => {
    const [clients, setClients] = useState<Client[]>()
    const [clientsPage, setClientsPage] = useState("")
    const [clientsError, setClientsError] = useState("")
    const [clientsLoading, setClientsLoading] = useState(false)

    useEffect(() => {
        if (refreshToken === 0) return;

        setClients([])
        setClientsError("")
        setClientsPage("")
        setClientsLoading(true)

        const pageParam = page === "" ? "" : `?page=${page}`

        const url = `${apiURL}${pageParam}`
        console.log(url)
        httpGet(url)
            .then((data) => {
                const response = data as ClientsResponse;
                
                if (response.status === "error") {
                    setClientsError(response.errorMessage)
                } else {
                    if (response.clients) {
                        setClients(response.clients);
                        setClientsPage(response.page);
                    } 
                }
            })
            .catch((error) => setClientsError(error.message || "Error fetching clients"))
            .finally(() => setClientsLoading(false))

    }, [refreshToken, page]);

    return { clients, clientsPage, clientsError, clientsLoading};
};
