import { List, Typography } from "@mui/material";
import { Droppable } from "react-beautiful-dnd";
import PipelineDeal from "./PipelineDeal";

const PipelineColumn = ({ column }: { column: any }) => {
  return (
    <div
      style={{
        backgroundColor: "gray",
        margin: 10,
        padding: 20,
        color: "white"
      }}
    >
      <Typography variant={"h3"}>{column.name}</Typography>
      <Droppable droppableId={column.id}>
        {(provided) => (
            <List ref={provided.innerRef} key={column.id}>
              {column.deals && column.deals.map((itemObject: any, index: number) => {
                return <PipelineDeal index={index} itemObject={itemObject} key={itemObject.id} />;
              })}
              {provided.placeholder}
            </List>
        )}
      </Droppable>
    </div>
  );
};

export default PipelineColumn;
