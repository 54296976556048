import { useState } from "react"
import { Amplify } from "aws-amplify"
import { confirmSignUp, signUp, resendSignUpCode } from "aws-amplify/auth"
import { Alert, Box, Button, Container, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Stack, TextField, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom";
import { AmplifySettings } from "../utils/amplifySettings"

Amplify.configure(AmplifySettings())


export default function SignUp() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [userID, setUserID] = useState("")
    const [confirmationCode, setConfirmationCode] = useState("");
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [clientType, setClientType] = useState("realestate")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [alertMessage, setAlertMessage] = useState("")

    const navigate = useNavigate();

    function emailChanged(event: React.ChangeEvent<HTMLInputElement>) {
        setEmail(event.currentTarget.value)
    }

    function passwordChanged(event: React.ChangeEvent<HTMLInputElement>) {
        setPassword(event.currentTarget.value)
    }

    function confirmationCodeChanged(event: React.ChangeEvent<HTMLInputElement>) {
        setConfirmationCode(event.currentTarget.value)
    }

    function firstNameChanged(event: React.ChangeEvent<HTMLInputElement>) {
        setFirstName(event.currentTarget.value)
    }

    function lastNameChanged(event: React.ChangeEvent<HTMLInputElement>) {
        setLastName(event.currentTarget.value)
    }

    function companyNameChanged(event: React.ChangeEvent<HTMLInputElement>) {
        setCompanyName(event.currentTarget.value)
    }

    function phoneChanged(event: React.ChangeEvent<HTMLInputElement>) {
        setPhoneNumber(event.currentTarget.value)
    }

    async function handleSignUp() {
        setAlertMessage("")

        if (phoneNumber === "") {
            setAlertMessage("Please enter a phone number.")
            return
        }

        if (firstName === "") {
            setAlertMessage("Please enter a first name.")
            return
        }

        if (lastName === "") {
            setAlertMessage("Please enter a last name.")
            return
        }

        if (phoneNumber === "") {
            setAlertMessage("Please enter a phone number.")
            return
        }

        try {
            const { isSignUpComplete, userId, nextStep } = await signUp({
                username: email,
                password: password,
                options: {
                    userAttributes: {
                        email: email,
                        phone_number: formatPhoneNumber(phoneNumber),
                        given_name: firstName,
                        family_name: lastName,
                        "custom:CompanyName": companyName,
                        "custom:ClientType": clientType
                    }
                }
            })

            setUserID(userId?.toString() || "")
            console.log("isSignUpComplete", isSignUpComplete)
            console.log("userId", userId)
            console.log("nextStep", nextStep)

            switch (nextStep.signUpStep) {
                case "CONFIRM_SIGN_UP":
                    console.log("Signup succeeded. Next step is to confirm the code.")
                    break
                case "COMPLETE_AUTO_SIGN_IN":
                    console.log("Please enter the confirmation code sent to your phone.")
                    break
                case "DONE":
                    console.log("Sign-up complete.")
                    navigate("/signin")
                    break
            }
        } catch (error) {
            const errMsg = getErrorMessage(error)
            console.log("Error signing up! ", errMsg)
            setAlertMessage(errMsg)
        }
    }

    function formatPhoneNumber(input: string): string {
        // Remove non-digit characters from the input
        const digitsOnly = input.replace(/\D/g, '');

        // Check if the resulting string is a valid phone number
        const isValidPhoneNumber = /^\d{10}$/.test(digitsOnly);

        if (isValidPhoneNumber) {
            // Format the phone number with "+1"
            const formattedPhoneNumber = `+1${digitsOnly}`;
            return formattedPhoneNumber;
        } else {
            // Invalid phone number
            return input;
        }
    }

    function getErrorMessage(error: unknown) {
        if (error instanceof Error) return error.message
        return String(error)
    }

    async function handleConfirmation() {
        try {
            const { isSignUpComplete, nextStep } = await confirmSignUp({
                username: userID,
                confirmationCode: confirmationCode
            });

            console.log("isSignUpComplete", isSignUpComplete)
            console.log("nextStep", nextStep)

            switch (nextStep.signUpStep) {
                case "CONFIRM_SIGN_UP":
                    console.log("Signup succeeded. Next step is to confirm the code.")
                    break
                case "COMPLETE_AUTO_SIGN_IN":
                    console.log("Please enter the confirmation code sent to your phone.")
                    break
                case "DONE":
                    console.log("Sign-up complete.")
                    navigate("/")
                    break
            }

        } catch (error) {
            console.log("Error confirming sign-up", error)
        }
    }

    const handleClientTypeChange = (event: SelectChangeEvent) => {
        if (event.target.value !== undefined) {
            setClientType(event.target.value)
        }
    };

    async function handleResendCode() {
        try {
            const { destination, deliveryMedium, attributeName } = await resendSignUpCode({
                username: email,
            })

            console.log("Code resent to", destination)
            console.log("via", deliveryMedium)
            console.log("using", attributeName)
        } catch (error) {
            console.log("Error signing up", error)
        }
    }

    return (
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Stack spacing={3} component={Paper} sx={{ width: 600 }}>
                <Typography variant="h3" sx={{ pl: 1, pt: 1 }}>Sign Up</Typography>
                {/* <FormControl > */}
                <Stack spacing={2} sx={{ pl: 1 }}>
                    {userID === "" &&
                        <Box>
                            <TextField id="companyName" label="Company Name" variant="outlined" onChange={companyNameChanged} value={companyName} sx={{ width: 400, mb: 2 }} />
                            <Stack direction="row" spacing={2} sx={{ width: 400, mb: 2 }}>
                                <TextField id="firstName" label="First Name" variant="outlined" onChange={firstNameChanged} value={firstName} sx={{ width: 200 }} />
                                <TextField id="lastName" label="Last Name" variant="outlined" onChange={lastNameChanged} value={lastName} sx={{ width: 200 }} />
                            </Stack>
                            <TextField id="phone" label="Phone" variant="outlined" onChange={phoneChanged} value={phoneNumber} sx={{ width: 400 }} />
                            <FormControl fullWidth size="small" sx={{ width: 300 }}>
                                <InputLabel id="trans-type-label">Client Type</InputLabel>
                                <Select labelId="clientType" label="Client Type" onChange={handleClientTypeChange} size="small" value={clientType}>
                                    <MenuItem value="automotive">Automotive</MenuItem>
                                    <MenuItem value="home">Home Services</MenuItem>
                                    <MenuItem value="insurance">Insurance</MenuItem>
                                    <MenuItem value="legal">Legal</MenuItem>
                                    <MenuItem value="mortgage">Mortgage</MenuItem>
                                    <MenuItem value="realestate">Real Estate</MenuItem>
                                    <MenuItem value="solar">Solar</MenuItem>                                   
                                </Select>
                            </FormControl>
                        </Box>
                    }
                    <TextField id="email" label="Email/Username" variant="outlined" onChange={emailChanged} value={email} sx={{ width: 400 }} />
                    {userID === ""
                        ? <Box>
                            <TextField id="password" type="password" label="Password" variant="outlined" onChange={passwordChanged} value={password} sx={{ width: 400 }} />
                            <Button onClick={handleSignUp} variant="outlined" sx={{ width: 400, mt: 2, mb: 2 }}>Sign-Up</Button>
                        </Box>
                        : <Box>
                            <TextField id="code" label="Confirmation Code" variant="outlined" onChange={confirmationCodeChanged} value={confirmationCode} sx={{ width: 400 }} />
                            <Button onClick={handleConfirmation} variant="outlined" sx={{ width: 400, mt: 2, mb: 2 }}>Confirm Code</Button>
                            <Button onClick={handleResendCode} variant="outlined" sx={{ width: 400, mt: 2, mb: 2 }}>Re-send Code</Button>
                        </Box>
                    }
                    {alertMessage !== "" && <Alert sx={{ width: 584 }} severity="error">{alertMessage}</Alert>}
                </Stack>
                <Box sx={{ pl: 1, pb: 0 }}></Box>
            </Stack>
        </Container>
    )
}