export function AmplifySettings() {
    const userPoolID = process.env.REACT_APP__USER_POOL_ID;
    const webClientID = process.env.REACT_APP__WEB_CLIENT_ID;

    const awsmobile = {
        "aws_project_region": "us-west-2",
        "aws_cognito_region": "us-west-2",
        "aws_user_pools_id": userPoolID,
        "aws_user_pools_web_client_id": webClientID,
        "oauth": {
            "domain": "thesharedev.auth.us-west-2.amazoncognito.com"
        },
        "aws_cognito_username_attributes": [
            "EMAIL"
        ],
        "aws_cognito_social_providers": [],
        "aws_cognito_signup_attributes": [
            "EMAIL"
        ],
        "aws_cognito_mfa_configuration": "OFF",
        "aws_cognito_mfa_types": [],
        "aws_cognito_password_protection_settings": {
            "passwordPolicyMinLength": 6,
            "passwordPolicyCharacters": []
        },
        "aws_cognito_verification_mechanisms": [
            "EMAIL"
        ]
    };

    return awsmobile;
}