import {useState, useEffect} from 'react';
import { httpGet, APIEndpoint, EndpointType } from '../utils/apiService'
import { VoiceJob, VoiceJobResponse } from '../models/voicejobs';

const apiURL =  APIEndpoint(EndpointType.voicequeue);

export const useGetvoiceJobs = (page: string, refreshToken: number, queued: boolean) => {
    const [voiceJobs, setvoiceJobs] = useState<VoiceJob[]>()
    const [voiceJobsPage, setvoiceJobsPage] = useState("")
    const [voiceJobsError, setvoiceJobsError] = useState("")
    const [voiceJobsLoading, setvoiceJobsLoading] = useState(false)

    useEffect(() => {
        if (refreshToken === 0) return;

        setvoiceJobs([])
        setvoiceJobsPage("")
        setvoiceJobsError("")
        setvoiceJobsLoading(true)

        let param = page === "" ? "" : `?page=${page}`

        if (queued) {
            if (param === "") {
                param = "?queued=true"
            } else {
                param += "&queued=true"
            }
        }

        const url = `${apiURL}${param}`
        console.log(url)
        httpGet(url)
            .then((data) => {
                const response = data as VoiceJobResponse;
                
                if (response.status === "error") {
                    setvoiceJobsError(response.errorMessage || "Error fetching voiceJobs")
                } else {
                    if (response.jobs) {
                        setvoiceJobs(response.jobs);
                        setvoiceJobsPage(response.page || "");
                    } 
                }
            })
            .catch((error) => setvoiceJobsError(error.message || "Error fetching voiceJobs"))
            .finally(() => setvoiceJobsLoading(false))

    }, [refreshToken, page, queued]);

    return { voiceJobs, voiceJobsPage, voiceJobsError, voiceJobsLoading};
};
