import {useState, useEffect} from 'react';
import { httpGet, APIEndpoint, EndpointType } from '../utils/apiService'
import { QueueStats, VoiceJobStatsResponse } from '../models/voicejobs';

const apiURL =  APIEndpoint(EndpointType.voicequeue);

export const useGetvoiceJobStats = (refreshToken: number) => {
    const [voiceJobStats, setvoiceJobStats] = useState<QueueStats>()
    const [voiceJobStatsError, setvoiceJobStatsError] = useState("")
    const [voiceJobStatsLoading, setvoiceJobStatsLoading] = useState(false)

    useEffect(() => {
        if (refreshToken === 0) return;

        setvoiceJobStats({} as QueueStats)
        setvoiceJobStatsError("")
        setvoiceJobStatsLoading(true)

        const url = `${apiURL}/stats`
        console.log(url)
        httpGet(url)
            .then((data) => {
                const response = data as VoiceJobStatsResponse;
                
                if (response.status === "error") {
                    setvoiceJobStatsError(response.errorMessage || "Error fetching voiceJobStats")
                } else {
                    if (response.stats) {
                        setvoiceJobStats(response.stats);
                    } 
                }
            })
            .catch((error) => setvoiceJobStatsError(error.message || "Error fetching voiceJobStats"))
            .finally(() => setvoiceJobStatsLoading(false))

    }, [refreshToken]);

    return { voiceJobStats, voiceJobStatsError, voiceJobStatsLoading};
};
