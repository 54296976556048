import {useState, useEffect} from 'react';
import { httpGet, APIEndpoint, EndpointType } from '../utils/apiService'
import { User, UsersResponse } from '../models/users';

const apiURL =  APIEndpoint(EndpointType.users);

export const useGetUser = (userID: string, refreshToken: number) => {
    const [user, setUser] = useState<User>()
    const [userError, setUserError] = useState("")
    const [userLoading, setUserLoading] = useState(false)

    useEffect(() => {
        if (refreshToken === 0 || userID === "") return;

        setUser({} as User)
        setUserError("")
        setUserLoading(true)

        const params = `/${userID}`

        const url = `${apiURL}${params}`
        console.log(url)
        httpGet(url)
            .then((data) => {
                const response = data as UsersResponse;
                
                if (response.status === "error") {
                    setUserError(response.errorMessage)
                } else {
                    if (response.users && response.users.length > 0) {
                        setUser(response.users[0]);
                    } 
                }
            })
            .catch((error) => setUserError(error.message || "Error fetching User"))
            .finally(() => setUserLoading(false))

    }, [refreshToken, userID]);

    return { user, userError, userLoading};
};
