import {useState, useEffect} from 'react';
import { httpGet, APIEndpoint, EndpointType } from '../utils/apiService'
import { Deal, DealsResponse } from '../models/deals';

const apiURL =  APIEndpoint(EndpointType.deals);

export const useGetDeals = (clientID: string, page: string, refreshToken: number) => {
    const [deals, setDeals] = useState<Deal[]>()
    const [dealsPage, setDealsPage] = useState("")
    const [dealsError, setDealsError] = useState("")
    const [dealsLoading, setDealsLoading] = useState(false)

    useEffect(() => {
        if (refreshToken === 0) return;

        setDeals([])
        setDealsPage("")
        setDealsError("")
        setDealsLoading(true)

        const pageParam = page === "" ? "" : `&page=${page}`
        const params = `?client-id=${clientID}${pageParam}`

        const url = `${apiURL}${params}`
        console.log(url)
        httpGet(url)
            .then((data) => {
                const response = data as DealsResponse;
                
                if (response.status === "error") {
                    setDealsError(response.errorMessage)
                } else {
                    if (response.deals) {
                        setDeals(response.deals);
                        setDealsPage(response.page);
                    } 
                }
            })
            .catch((error) => setDealsError(error.message || "Error fetching deals"))
            .finally(() => setDealsLoading(false))

    }, [clientID, refreshToken, page]);

    return { deals, dealsPage, dealsError, dealsLoading};
};
