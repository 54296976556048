import { useState } from "react";
import { AutocompleteUser, useGetUsers } from "../hooks/useGetUsers";
import { Alert, Autocomplete, Box, Button, Stack, TextField, Typography } from "@mui/material";
import LoadingProgress from "./LoadingProgress";
import { User } from "../models/users";

interface Props {
    onLoginUserChanged: (user: User) => void
    onClose: () => void
}

export default function LoginAs(props: Props) {
    // State
    const [selectedUser, setSelectedUser] = useState<User>();

    // Hooks
    const { users, autocompleteUsers, usersLoading, usersError } = useGetUsers("", 1);

    const userIDChanged = (event: React.SyntheticEvent<Element, Event>, value: AutocompleteUser | null) => {
        if (value == null) {
            const user = {} as User
            setSelectedUser(user)
        } else {
            if (users) {
                for (const user of users) {
                    if (user.id === value.id) {
                        setSelectedUser(user)
                        break;
                    }
                }
            }
        }
    }

    const changeUser = () => {
        if (selectedUser && selectedUser.id !== "") {
            props.onLoginUserChanged(selectedUser)
        } else {
            alert("Please select a user to login as.")
        }
    }

    return (
        <Stack sx={{ margin: 1 }} direction="column" spacing={1} alignItems="flex-start" justifyContent="space-between">
            <Typography variant="h5" sx={{ ml: 1 }}>Login As</Typography>

            {usersLoading && <LoadingProgress title="Getting Users..." />}
            {usersError && <Alert severity="error" sx={{ m: 1 }}>{usersError}</Alert>}
            <Box sx={{ width: 600, marginTop: 2 }}>
                <Autocomplete
                    disablePortal
                    isOptionEqualToValue={(
                        option: AutocompleteUser,
                        value: AutocompleteUser
                    ) => option.id === value.id}
                    id="existingUser"
                    onChange={userIDChanged}
                    options={autocompleteUsers}
                    size="small"
                    sx={{ width: 400 }}
                    renderInput={(params) => (
                        <TextField {...params} label="User Name" />
                    )}
                />
            </Box>
            <Box>
            
                    <Button onClick={changeUser} variant="contained" sx={{ mt:2, width: 120 }}>Login As</Button>
                    <Button onClick={() => props.onClose()} variant="contained" sx={{ ml:1, mt:2, width: 120 }}>Cancel</Button>
                
            </Box>
        </Stack>

    );
}