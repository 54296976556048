import { useEffect, useState } from "react"
import { Amplify } from "aws-amplify"
import { AuthError, signIn, getCurrentUser } from "aws-amplify/auth"
import { Alert, Box, Button, Container, Link, Paper, Stack, TextField, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { AmplifySettings } from "../utils/amplifySettings"

Amplify.configure(AmplifySettings())

export default function SignIn() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [alertMessage, setAlertMessage] = useState("")

    const navigate = useNavigate();

    useEffect(() => {
        async function checkAuth() {
            try {
                const { username, userId, signInDetails } = await getCurrentUser();

                console.log("username", username);
                console.log("user id", userId);
                console.log("sign-in details", signInDetails);
                navigate("/home");
                setIsAuthenticated(true);
            } catch (error: any) {
                setIsAuthenticated(false);
            }
        }

        checkAuth();
    }, [navigate])

    function getErrorMessage(error: unknown) {
        if (error instanceof Error) return error.message
        return String(error)
    }

    function emailChanged(event: React.ChangeEvent<HTMLInputElement>) {
        setEmail(event.currentTarget.value)
    }

    function passwordChanged(event: React.ChangeEvent<HTMLInputElement>) {
        setPassword(event.currentTarget.value)
    }

    async function handleSubmit() {
        try {
            await signIn({
                username: email,
                password: password,
            })

            const { username, userId, signInDetails } = await getCurrentUser();

            console.log("username", username);
            console.log("user id", userId);
            console.log("sign-in details", signInDetails);
            navigate("/home");

        } catch (error: any) {
            console.log ("Sign in failed:,", error)
            if (error instanceof AuthError) {
                setAlertMessage(getErrorMessage(error))
            }
        }
    }

    return (
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Stack spacing={3} component={Paper} sx={{ width: 600 }}>
                <Typography variant="h3" sx={{ pl: 1, pt: 1 }}>Sign In</Typography>
                
                { isAuthenticated && <Typography variant="h6" sx={{ pl: 1, pb: 1 }}>You are already signed in</Typography> }
                
                <Stack spacing={2} sx={{ pl: 1 }}>
                    <TextField id="email" label="Email" variant="outlined" onChange={emailChanged} value={email} sx={{ width: 400 }} />
                    <TextField id="password" type="password" label="Password" variant="outlined" onChange={passwordChanged} value={password} sx={{ width: 400 }} />
                    {alertMessage !== "" && <Alert sx={{width:584}} severity="error">{alertMessage}</Alert>}
                    <Button onClick={handleSubmit} variant="outlined" sx={{ width: 400, mb: 2 }}>Sign-In</Button>
                </Stack>
                <Box sx={{ pl: 1, pb: 0 }}>Don't have an account? <Link sx={{mr:3}} href="/signup" variant="body2">
                    {"Sign Up"}
                  </Link>
                  Forgot Password? <Link href="/forgot-password" variant="body2">Reset</Link>
                </Box>  
            </Stack>
        </Container>
    )
}