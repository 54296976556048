import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { Stack, Typography } from "@mui/material";

interface Props {
  title: string
}

export default function LoadingProgress(props: Props) {
  return (
    <Box
      sx={{
        display: "flex",
        m: 0,
        p: 0,
        width: "100%",
        height: "50vh",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Stack alignItems="center" justifyContent="center">
        <CircularProgress />
        <Typography variant="caption">{props.title}</Typography>
      </Stack>
    </Box>
  );
}
