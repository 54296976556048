export function ISODateString(d: Date) {
    function pad(n: number) { return n < 10 ? '0' + n : n }
    return d.getUTCFullYear() + '-'
        + pad(d.getUTCMonth() + 1) + '-'
        + pad(d.getUTCDate()) + 'T'
        + pad(d.getUTCHours()) + ':'
        + pad(d.getUTCMinutes()) + ':'
        + pad(d.getUTCSeconds()) + '.'
        + pad(d.getUTCMilliseconds()) + 'Z'
}

export const LocalTime = (time: string | undefined): string => {
    if (!time) {
        return "";
    }
    const localTime = new Date(time).toLocaleTimeString();
    const localDate = new Date(time).toLocaleDateString();
    return "Local Time: " + localDate + " " + localTime;
}