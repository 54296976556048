import { Amplify } from "aws-amplify"
import { getCurrentUser, signOut } from "aws-amplify/auth"
import { useEffect, useState } from "react"
import { Box, Button, Typography } from "@mui/material"
import { AmplifySettings } from "../utils/amplifySettings"

Amplify.configure(AmplifySettings())

export default function SignOut() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        async function isAuthenticated() {
            try {
                const { username, userId, signInDetails } = await getCurrentUser();

                console.log("username", username);
                console.log("user id", userId);
                console.log("sign-in details", signInDetails);

                setIsAuthenticated(true);
            } catch (error: any) {
                console.log("Not authenticated:", error)
                setIsAuthenticated(false);
            }
        }

        isAuthenticated();
    }, [])
    
    useEffect(() => {
        async function doSignOut() {
            try {
                await signOut()
            } catch (error: any) {
                console.log("Sign out failed:", error)
                setIsAuthenticated(false)
            }
        }
        doSignOut()

    }, [])

    return (
        <Box>
            { isAuthenticated 
              ? <Typography variant="h6" sx={{ pl: 1, pb: 1 }}>You are already currently signed in</Typography>
              : <Box>
                    <Typography variant="h6" sx={{ pl: 1, pb: 1 }}>You have been signed out</Typography>
                    <Button variant="contained" color="primary" sx={{ pl: 1, pb: 1 }} href="/signin">Sign In</Button>
                </Box>
            }
        </Box>
    )
}