import { Alert, FormControl, Grid, IconButton, InputLabel, Select, SelectChangeEvent, Snackbar, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import PipelineColumn from "../../components/PipelineColumn";
import { useGetPopulatedPipeline } from "../../hooks/useGetPopulatedPipeline";
import { User } from "../../models/users";
import { Pipeline, PopulatedStage, StageChangeRequest, StageChangeResponse } from "../../models/pipelines";
import LoadingProgress from "../../components/LoadingProgress";
import { useGetPipelines } from "../../hooks/useGetPipelines";
import { APIEndpoint, EndpointType, httpPut } from "../../utils/apiService";
import CloseIcon from "@mui/icons-material/Close";
//import { AbstractDeal } from "../../models/deals";
interface Props {
  activeUser: User
}

const PipelineView = (props: Props) => {
  const [selectedPipelineID, setSelectedPipelineID] = useState("");
  const [columns, setColumns] = useState<PopulatedStage[]>([]);
  const [stageChangeRequest, setStageChangeRequest] = useState<StageChangeRequest>();
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbarMsg, setSnackbarMsg] = useState("")

  const { pipelines, pipelinesError, pipelinesLoading } = useGetPipelines(props.activeUser.clientID, "", 1);
  const { populatedPipeline, populatedPipelineError, populatedPipelineLoading } = useGetPopulatedPipeline(props.activeUser.clientID, selectedPipelineID, 1);

  useEffect(() => {
    if (populatedPipeline) {
      const newColumns = populatedPipeline.stages;
      //console.log(newColumns);
      console.log("Setting columns from populated pipeline");
      setColumns(newColumns);
    }
  }, [populatedPipeline]);

  useEffect(() => {
    console.log("Columns:", columns);
  }, [columns]);

  useEffect(() => {
    if (pipelines && pipelines.length > 0) {
      setSelectedPipelineID(pipelines[0].id);
    }
  }, [pipelines]);

  useEffect(() => {
    if (stageChangeRequest) {
      saveStateChanges();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stageChangeRequest]);

  const onDragEnd = ({ source, destination }: { source: any, destination: any }) => {
    console.log("onDragEnd Source:", source);
    console.log("onDragEnd Destination:", destination);
    // Make sure we have a valid destination
    if (destination === undefined || destination === null) return null;

    // Make sure we're actually moving the item
    if (
      source.droppableId === destination.droppableId &&
      destination.index === source.index
    )
      return null;

    // Get the PopulatedStage where source.droppableId is the same as the id of the stage
    const startStage = columns.find((stage) => stage.id === source.droppableId);
    const endStage = columns.find((stage) => stage.id === destination.droppableId);

    if (!startStage || !endStage) return null;

    // If start is the same as end, we're in the same column
    if (startStage === endStage) {
      // Move the item within the list
      // Start by making a new list without the dragged item
      const newDealsList = startStage.deals.filter((_, idx) => idx !== source.index);

      // Then insert the item at the right location
      newDealsList.splice(destination.index, 0, startStage.deals[source.index]);

      startStage.deals = newDealsList;
      const startStageIndex = columns.findIndex((stage) => stage.id === startStage.id);
      const newColumns = columns;
      newColumns[startStageIndex] = startStage;

      // Update the state
      setColumns(newColumns);

      // Create a new stage change request
      const newStageChangeRequest = {
        pipelineID: selectedPipelineID,
        fromStageID: startStage.id,
        fromStageDealIDs: startStage.deals.map((deal) => deal.id),
        toStageID: endStage.id,
        toStageDealIDs: newDealsList.map((deal) => deal.id),
        dealID: "",
      };

      setStageChangeRequest(newStageChangeRequest);
      return null;
    } else {
      // If start is different from end, we need to update multiple columns
      // Filter the start list like before
      const newDealsStartList = startStage.deals.filter((_, idx) => idx !== source.index);

      // Make a new end list array
      const newDealsEndList = endStage.deals || [];

      // Insert the item into the end list
      newDealsEndList.splice(destination.index, 0, startStage.deals[source.index]);

      const startStageIndex = columns.findIndex((stage) => stage.id === startStage.id);
      const endStageIndex = columns.findIndex((stage) => stage.id === endStage.id);

      const newColumns = columns;
      startStage.deals = newDealsStartList;
      newColumns[startStageIndex] = startStage;

      endStage.deals = newDealsEndList;
      newColumns[endStageIndex] = endStage;

      const dealID = endStage.deals[destination.index].id;

      setColumns(newColumns);

      // Create a new stage change request
      const newStageChangeRequest = {
        pipelineID: selectedPipelineID,
        fromStageID: startStage.id,
        fromStageDealIDs: startStage.deals.map((deal) => deal.id),
        toStageID: endStage.id,
        toStageDealIDs: endStage.deals.map((deal) => deal.id),
        dealID: dealID,
      };

      setStageChangeRequest(newStageChangeRequest);

      return null;
    }
  };

  const handleSelectedPipelineChange = (event: SelectChangeEvent) => {
    console.log(event.target.value);
    if (event.target.value !== undefined) {
      setSelectedPipelineID(event.target.value)
    }
  };

  const saveStateChanges = () => {
    const apiURL =  APIEndpoint(EndpointType.pipelines) + `/${stageChangeRequest?.pipelineID}?client-id=${props.activeUser.clientID}&action=stage-change`;
    httpPut(apiURL, JSON.stringify(stageChangeRequest))
    .then((data) => {
        const response = data as StageChangeResponse;
        if (response.status === "success") {
            setSnackbarMsg("Updated successfully")
            setOpenSnackbar(true)
        } else if (response.status === "error") {
            setSnackbarMsg(response.errorMessage)
            setOpenSnackbar(true)
        }
        
    })
    .catch((error) => { 
        console.log("Update error: " + error.message)
    });  
}

const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
  if (reason === 'clickaway') {
      return;
  }

  setOpenSnackbar(false);
};

const snackbarAction = (
  <Fragment>
      <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleSnackbarClose}
      >
          <CloseIcon fontSize="small" />
      </IconButton>
  </Fragment>
);

  return (
    <Grid>
      <Typography variant="h4" sx={{ m: 2 }}>{}</Typography>
      <FormControl fullWidth sx={{ margin: 2, pr: 4 }}>
        <InputLabel id="pipelines-label">Pipeline</InputLabel>
        <Select labelId="pipelines-label" id="pipeline-select" label="Pipeline-label" onChange={handleSelectedPipelineChange}>
          {pipelines && pipelines.map((pipeline: Pipeline) => {
            return <option key={pipeline.id} value={pipeline.id}>{pipeline.name}</option>
          })}
        </Select>
      </FormControl>

      {pipelinesLoading && <LoadingProgress title="Getting Pipelines..." />}
      {pipelinesError && <Alert severity="error" sx={{ m: 1 }}>{pipelinesError}</Alert>}
      {populatedPipelineLoading && <LoadingProgress title="Getting Populated Pipeline..." />}
      {populatedPipelineError && <Alert severity="error" sx={{ m: 1 }}>{populatedPipelineError}</Alert>}

      <DragDropContext onDragEnd={onDragEnd}>
        <Grid container direction={"row"}>
          {columns && Object.values(columns).map((column) => {
            //console.log("Column:", column);
            return (
              <Grid item>
                <PipelineColumn column={column} key={column.id} />
              </Grid>
            );
          })}
        </Grid>
      </DragDropContext>

      <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={snackbarMsg}
                action={snackbarAction}
            />
    </Grid>
  );
};

export default PipelineView;
