import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

const Home = () => {

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            High i.Q. CRM Test Application
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >

          </Paper>
          <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                marginTop:2
              }}
            >
            Stuff goes here...
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Home;
