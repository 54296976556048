import { Alert, Box, Grid, IconButton, Link, Modal, Paper, Snackbar, Stack, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import LoadingProgress from "../../components/LoadingProgress";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import JSONInput from "../../components/JSONInput";
import  NoData from "../../components/NoData";
import { APIEndpoint, EndpointType, httpDelete, httpPost, httpPut } from "../../utils/apiService";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDialog from "../../components/ConfirmDialog";
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { User } from "../../models/users";
import CopyToClipboard from "../../components/CopyToClipboard";
import { ScaleUnit, ScaleUnitsResponse, ScaleUnitStatus, ScaleUnitUtilizationMetrics } from "../../models/scaleunits";
import { useGetVoiceScaleUnits } from "../../hooks/useGetVoiceScaleUnits";

interface Props {
    activeUser: User
}

export default function ScaleUnitResources(props: Props) {
    // State
    const [page, setPage] = useState("");
    const [refreshToken, setRefreshToken] = useState(0);
    const [openJsonInput, setOpenJsonInput] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [snackbarMsg, setSnackbarMsg] = useState("")
    const [jsonInputData, setJsonInputData] = useState("")
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [selectedItems, setSelectedItems] = useState<string[]>([]);

    // Hooks
    const {voiceScaleUnits, voiceScaleUnitsError, voiceScaleUnitsLoading, voiceScaleUnitsPage } = useGetVoiceScaleUnits(page, refreshToken);

    const columns = [
        { field: 'id', headerName: 'ID', width: 160, renderCell: (params: GridRenderCellParams) => {
            return        <Box><Link
            component="button"
            variant="body2"
            onClick={() => {
              setJsonInputData(JSON.stringify(params.row));
              setOpenJsonInput(true);
            }}
            >
            {params.value}
          </Link><CopyToClipboard value={params.value} /> </Box>
        } },
        { field: 'createdAt', headerName: 'Created At', width: 200 },
        { field: 'ipAddress', headerName: 'IP Address', width: 120 },
        { field: 'status', headerName: 'Status', width: 120 },
        { field: 'activeCallMetrics', headerName: 'Active Calls', width: 100, renderCell: (params: GridRenderCellParams) => {
            return <>{params.row.activeCallMetrics.outboundCallCount}</>
        },},
        { field: 'totalCallMetrics', headerName: 'Total Calls', width: 100, renderCell: (params: GridRenderCellParams) => {
            return <>{params.row.totalCallMetrics.outboundCallCount}</>
        },},
        { field: 'scaleUnitUtilizationMetrics', headerName: 'Health', width: 380, renderCell: (params: GridRenderCellParams) => {
            return <>{formatUtilizationMetrics(params.row.scaleUnitUtilizationMetrics)}</>
        },},
        { field: 'version', headerName: 'Version', width: 80 },
        { field: 'idleTime', headerName: 'Idle Time', width: 100 },
        { field: 'upTime', headerName: 'Up Time', width: 100 },
    ];

    useEffect(() => {
        setRefreshToken(1);
    }, []);

    const handleJsonSave = (json: string) => {
        console.log("saving json: ", json)
        setOpenJsonInput(false);

        const scaleUnit = JSON.parse(json) as ScaleUnit;
        if (scaleUnit.id === "") {
            createItem(json)
        } else {
            updateItem(json, scaleUnit.id)
        }
    }

    const createItem = (json: string) => {
        const apiURL =  APIEndpoint(EndpointType.voicescaleunits)
        httpPost(apiURL, json)
        .then((data) => {
            const response = data as ScaleUnitsResponse;
            if (response.status === "success") {
                setSnackbarMsg("Created successfully")
                setOpenSnackbar(true)
                setRefreshToken(refreshToken + 1)
            } else if (response.status === "error") {
                setSnackbarMsg(response.errorMessage)
                setOpenSnackbar(true)
            }
            
        })
        .catch((error) => { 
            console.log("Creation error: " + error.message)
        });  
    }

    const updateItem = (json: string, id: string) => {
        const apiURL =  APIEndpoint(EndpointType.voicescaleunits) + `/${id}`
        httpPut(apiURL, json)
        .then((data) => {
            const response = data as ScaleUnitsResponse;
            if (response.status === "success") {
                setSnackbarMsg("Updated successfully")
                setOpenSnackbar(true)
                setRefreshToken(refreshToken + 1)
            } else if (response.status === "error") {
                setSnackbarMsg(response.errorMessage)
                setOpenSnackbar(true)
            }
            
        })
        .catch((error) => { 
            console.log("Update error: " + error.message)
        });  
    }

    const promptForDelete = () => {
        if (selectedItems.length === 0) {
            setSnackbarMsg("No items selected for deletion")
            setOpenSnackbar(true)
        } else {
            setOpenConfirmDialog(true)
        }
    }

    const formatUtilizationMetrics = (utilizationMetrics: ScaleUnitUtilizationMetrics): string => {
        const cpu = utilizationMetrics.cpuUtilization.toFixed(0) + "%"
        const memory = utilizationMetrics.memoryUtilization.toFixed(0) + "%"
        const networkIn = utilizationMetrics.networkInUtilization.toFixed(0) + "%"
        const networkOut = utilizationMetrics.networkOutUtilization.toFixed(0) + "%"
        return `CPU: ${cpu} | Memory: ${memory} | Net In: ${networkIn} | Net Out: ${networkOut}`
    }

    const deleteItem = (id: string) => {
        const apiURL =  APIEndpoint(EndpointType.voicescaleunits) + `/${id}`
        httpDelete(apiURL)
        .then((data) => {
            const response = data as ScaleUnitsResponse;
            if (response.status === "success") {
                setSnackbarMsg("Deleted successfully")
                setOpenSnackbar(true)
                setRefreshToken(refreshToken + 1)
            } else if (response.status === "error") {
                setSnackbarMsg(response.errorMessage)
                setOpenSnackbar(true)
            }
            
        })
        .catch((error) => { 
            console.log("Deletion error: " + error.message)
        });  
    }

    const newScaleUnit = ():string => {
        const scaleUnit: ScaleUnit = {
            id: "",
            ipAddress: "192.168.1.1",
            portNumber: 2030,
            version: "1.0",
            scaleUnitUtilizationMetrics: {
                memoryUtilization: 0,
                cpuUtilization: 0,
                networkInUtilization: 0,
                networkOutUtilization: 0
            },
            activeCallMetrics: {
                outboundCallCount: 0,
                inboundCallCount: 0
            },
            totalCallMetrics: {
                outboundCallCount: 0,
                inboundCallCount: 0
            },
            idleTime: 0,
            upTime: 0,
            maxConcurrentCalls: 10,
            status: ScaleUnitStatus.Active,
        }

        return JSON.stringify(scaleUnit)
    }

    const handleAddItemClose = () => {
        setOpenJsonInput(false);
    };

    const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
    };

    const onConfirmDialogClicked = (confirmed: boolean) => {
        setOpenConfirmDialog(false);
        if (confirmed) {
            selectedItems.forEach(selectedItemID => {
                deleteItem(selectedItemID)
            });
        }
    }

    const snackbarAction = (
        <Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleSnackbarClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </Fragment>
    );

    const modalStyle = {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: '#636363',
        border: '1px solid #ededed',
        boxShadow: 24,
        p: 1,
        height: 800,
    };

    return (
        <Paper sx={{ margin: 1 }}>
            <Stack direction="row" spacing={1} alignItems="flex-start" justifyContent="space-between">
                <Typography variant="h5" sx={{ ml: 1 }}>ScaleUnit Resources</Typography>
                <Stack direction="row" spacing="1">
                    <IconButton aria-label="New" size="medium" type="button" onClick={() => {setJsonInputData(newScaleUnit()); setOpenJsonInput(true)}} sx={{ height: 40, marginTop: 1 }} >
                        <AddCircleOutlineIcon  sx={{color:"#00ff00"}}  />
                    </IconButton>
                    <IconButton aria-label="Delete" size="medium" type="button" onClick={() => promptForDelete()} sx={{ height: 40, marginTop: 1 }} >
                        <DeleteIcon sx={{color:"#ff0000"}} />
                    </IconButton>
                    {voiceScaleUnitsPage && voiceScaleUnitsPage !== "" &&
                        <IconButton aria-label="Refresh" size="medium" type="button" onClick={() => setPage(voiceScaleUnitsPage)} sx={{ height: 40, marginTop: 1 }} >
                            <SkipNextIcon  sx={{color:"#ffff00"}}  />
                        </IconButton>
                    }
                    <IconButton aria-label="Refresh" size="medium" type="button" onClick={() => setRefreshToken(refreshToken + 1)} sx={{ height: 40, marginTop: 1 }} >
                        <RefreshIcon  sx={{color:"#00ffff"}}  />
                    </IconButton>
                </Stack>
            </Stack>
            {voiceScaleUnitsLoading && <LoadingProgress title="Getting ScaleUnits..." />}
            {voiceScaleUnitsError && <Alert severity="error" sx={{m:1}}>{voiceScaleUnitsError}</Alert>}

            <Grid sx={{height:"88vh"}}>
                <DataGrid rows={voiceScaleUnits || []} columns={columns} sx={{ mt: 2 }} slots={{noRowsOverlay: NoData}} checkboxSelection
                                        onRowSelectionModelChange={(ids) => {
                                            const selectedIDs = new Set(ids);
                                            const arrayIDs: string[] = [];
                                            selectedIDs.forEach(v => arrayIDs.push(v.toString()));
                                            setSelectedItems(arrayIDs)
                                          }} />
            </Grid>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={snackbarMsg}
                action={snackbarAction}
            />
            <Modal open={openJsonInput} onClose={handleAddItemClose}>
                <Box sx={modalStyle} >
                    <JSONInput title="Create New Scale Unit" json={jsonInputData} onSave={handleJsonSave} />
                </Box>
            </Modal>
            <ConfirmDialog open={openConfirmDialog} title="Delete Scale Unit" message="Are you sure you want to delete the selected items?" confirmText="Yes" denyText="No" onConfirm={onConfirmDialogClicked} />
        </Paper>
    )
}

