import {useState, useEffect} from 'react';
import { httpGet, APIEndpoint, EndpointType } from '../utils/apiService'
import { Pipeline, PipelinesResponse } from '../models/pipelines';

const apiURL =  APIEndpoint(EndpointType.pipelines);

export const useGetPipelines = (clientID: string, page: string, refreshToken: number) => {
    const [pipelines, setPipelines] = useState<Pipeline[]>()
    const [pipelinesPage, setPipelinesPage] = useState("")
    const [pipelinesError, setPipelinesError] = useState("")
    const [pipelinesLoading, setPipelinesLoading] = useState(false)

    useEffect(() => {
        if (refreshToken === 0) return;

        setPipelines([])
        setPipelinesPage("")
        setPipelinesError("")
        setPipelinesLoading(true)

        const pageParam = page === "" ? "" : `&page=${page}`
        const params = `?client-id=${clientID}${pageParam}`

        const url = `${apiURL}${params}`
        console.log(url)
        httpGet(url)
            .then((data) => {
                const response = data as PipelinesResponse;
                
                if (response.status === "error") {
                    setPipelinesError(response.errorMessage)
                } else {
                    if (response.pipelines) {
                        setPipelines(response.pipelines);
                        setPipelinesPage(response.page);
                    } 
                }
            })
            .catch((error) => setPipelinesError(error.message || "Error fetching pipelines"))
            .finally(() => setPipelinesLoading(false))

    }, [clientID, refreshToken, page]);

    return { pipelines, pipelinesPage, pipelinesError, pipelinesLoading};
};
