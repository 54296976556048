import {useState, useEffect} from 'react';
import { httpGet, APIEndpoint, EndpointType } from '../utils/apiService'
import { PopulatedPipeline, PopulatedPipelineResponse } from '../models/pipelines';

const apiURL =  APIEndpoint(EndpointType.pipelines);

export const useGetPopulatedPipeline = (clientID: string, pipelineID: string, refreshToken: number) => {
    const [populatedPipeline, setPopulatedPipeline] = useState<PopulatedPipeline>()
    const [populatedPipelineError, setPopulatedPipelineError] = useState("")
    const [populatedPipelineLoading, setPopulatedPipelineLoading] = useState(false)

    useEffect(() => {
        if (refreshToken === 0 || clientID === "" || pipelineID === "") return;

        setPopulatedPipeline({} as PopulatedPipeline)
        setPopulatedPipelineError("")
        setPopulatedPipelineLoading(true)

        const params = `/${pipelineID}?client-id=${clientID}&populated=true`

        const url = `${apiURL}${params}`
        console.log(url)
        httpGet(url)
            .then((data) => {
                const response = data as PopulatedPipelineResponse;
                
                if (response.status === "error") {
                    setPopulatedPipelineError(response.errorMessage)
                } else {
                    if (response.pipeline) {
                        setPopulatedPipeline(response.pipeline);
                    } 
                }
            })
            .catch((error) => setPopulatedPipelineError(error.message || "Error fetching populated pipeline"))
            .finally(() => setPopulatedPipelineLoading(false))

    }, [clientID, pipelineID, refreshToken]);

    return { populatedPipeline, populatedPipelineError, populatedPipelineLoading};
};
