import { useState } from "react"
import { Amplify } from "aws-amplify"
import { AuthError, resetPassword, confirmResetPassword } from "aws-amplify/auth"
import { Alert, Button, Container, Paper, Stack, TextField, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { AmplifySettings } from "../utils/amplifySettings"

Amplify.configure(AmplifySettings())

export default function ForgotPassword() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmationCode, setConfirmationCode] = useState("");
    const [alertMessage, setAlertMessage] = useState("")
    const [showConfirmation, setShowConfirmation] = useState(false)

    const navigate = useNavigate();

    function getErrorMessage(error: unknown) {
        if (error instanceof Error) return error.message
        return String(error)
    }

    function emailChanged(event: React.ChangeEvent<HTMLInputElement>) {
        setEmail(event.currentTarget.value)
    }

    function passwordChanged(event: React.ChangeEvent<HTMLInputElement>) {
        setPassword(event.currentTarget.value)
    }

    function confirmationCodeChanged(event: React.ChangeEvent<HTMLInputElement>) {
        setConfirmationCode(event.currentTarget.value)
    }

    async function handleSubmit() {
        try {
            const { nextStep } = await resetPassword({
                username: email
            });

            switch (nextStep.resetPasswordStep) {
                case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
                    const codeDeliveryDetails = nextStep.codeDeliveryDetails;
                    console.log(
                        `Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`
                    );
                    // Collect the confirmation code from the user and pass to confirmResetPassword.
                    setShowConfirmation(true)
                    break;
                case 'DONE':
                    console.log('Successfully reset password.');
                    break;
            }

        } catch (error: any) {
            console.log("Reset password failed:,", error)
            if (error instanceof AuthError) {
                setAlertMessage(getErrorMessage(error))
            }
        }
    }

    async function handleConfirmationSubmit() {
        try {
            await confirmResetPassword({
                username: email,
                newPassword: password,
                confirmationCode: confirmationCode
            });
            navigate("/signin");
        } catch (error: any) {
            console.log("Confirmation reset password failed:,", error)
            if (error instanceof AuthError) {
                setAlertMessage(getErrorMessage(error))
            }
        }
    }

    return (
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Stack spacing={3} component={Paper} sx={{ width: 600 }}>
                <Typography variant="h3" sx={{ pl: 1, pt: 1 }}>Reset Password</Typography>
                <Stack spacing={2} sx={{ pl: 1 }}>
                    {showConfirmation 
                    ? <Stack spacing={2} sx={{ pl: 1 }}>
                        <Typography>A confirmation code was sent to the email address specified if it exists.</Typography>
                        <TextField id="confirmation" label="Confirmation Code" variant="outlined" onChange={confirmationCodeChanged} value={confirmationCode} sx={{ width: 400 }} />
                        <TextField id="password" type="password" label="Password" variant="outlined" onChange={passwordChanged} value={password} sx={{ width: 400 }} />
                        <Button onClick={handleConfirmationSubmit} variant="outlined" sx={{ width: 400, mb: 2 }}>Confirmation Reset</Button>
                      </Stack>
                    : <Stack spacing={2} sx={{ pl: 1 }}>
                        <TextField id="email" label="Email" variant="outlined" onChange={emailChanged} value={email} sx={{ width: 400 }} />
                        <Button onClick={handleSubmit} variant="outlined" sx={{ width: 400, mb: 2 }}>Reset Password</Button>
                      </Stack>
                    }
                    {alertMessage !== "" && <Alert sx={{ width: 584 }} severity="error">{alertMessage}</Alert>}
                </Stack>
            </Stack>
        </Container>
    )
}